import React, { Component } from 'react';
import './AboutUs.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export class AboutUs extends Component {
    static displayName = AboutUs.name;


    render() {
        return (
            <div className="backdrop">
            
            <div className="full-width">
                

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid className='box1' item xs={8} md={8}>
                            <div className='box'>
                                <div className='text-box'>
                                <h1 className='title'>About Us</h1>
                                <p className='center_para'>  Welcome to our A&T!  We understand that accounting can be overwhelming, but it doesn't have to be. We are here to help you navigate through the complexities of financial management and partner with you in your business so that you can focus on what really matters - making important informed decisions in driving the growth and profitability of your business, as well as enjoying your life.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className='picbox1' item xs={4} md={4}>  </Grid>
                        <Grid item xs={12} md={12}> </Grid>
                        <Grid item xs={12} md={12}>
                            <div className='box2'>
                                <h1 className='title2'> <span>Experience Matters.</span> <br /> Powered with Xero.  </h1>
                            </div>
                        </Grid>
                        <Grid className='picbox2' item xs={7} md={7}> </Grid>
                        <Grid className='box3' item xs={5} md={5}>
                            <div className='box3-sub'>
                                <div className='text-box3'>
                                    <p className='center_para2'>  Here at A & T. We are dedicated to help you achieve your financial goals and we offer a range of services, from bookkeeping and tax preparation to financial planning, consulting and streamlining of financial and reporting processes. <br /><br />

                                        At our core, we believe in a humanistic approach to accounting and finance. We understand that numbers are important, but so are people. That's why we take the time to get to know you and your business. <br /><br />We take a personalized approach to each client, tailoring our services to meet your unique needs. Transparency and communication are essential to building trust. <br /><br />We believe that building strong relationships with our clients is key to providing the best possible service. 

                                        We believe that your success is our success. We are committed to helping you and take pride in being a part of your journey. <br /><br />Contact us today to learn more about how we can help you with your business needs. <br /><br />
                                    </p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>


                
                </div>    
            </div>
        );
    }
}
