import React, { useRef } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';
import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

function ContactForm() { 
    var REACT_APP_TEMPLATE_ID = 'template_m9upm0h';
    var REACT_APP_SERVICE_ID = 'atdevacc1';
    var REACT_APP_PUBLIC_KEY = 'QPEfQX2J2ztfosB1r';
    const sendEmail = (e) => {
    e.preventDefault();
    emailjs
        .sendForm(
            REACT_APP_SERVICE_ID,
            REACT_APP_TEMPLATE_ID,
            Form.current,
            REACT_APP_PUBLIC_KEY
        )
        .then(
            (result) => {
                alert('Thank you for contacting us!');
                console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
    };


    

    return (
        <div>
            
            <form className='cf contact-form' ref={Form} onSubmit={sendEmail} >
                <div>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>

                            <Grid className='' item xs={0} md={2}></Grid>
                            <Grid className='' item xs={0} md={8}><h1 className="contactform_header" >Fill the Form.<br /> It's easy.</h1></Grid>
                            <Grid className='' item xs={0} md={2}></Grid>
                            

                            <Grid className='' item xs={0} md={2}></Grid>
                            <Grid className='' item xs={12} md={4}>
                                <div>
                                    <input className='form-input' type='text' placeholder='Name' name='user_name' required />
                                    <input className='form-input' type='tel' pattern="[0-9]{8}" placeholder='Contact Number' name='contact_number' required />

                                    <br/>

                                    {/*
                                    <label htmlFor="identity">I am:&nbsp;</label>
                                    <select className='identity_dropdown' id="identity" name="identity" required>
                                        <option value="New Start-up">New Start-up</option>
                                        <option value="Fresh Idea">Fresh Idea</option>
                                        <option value="Growth Business">Growth Business</option>
                                        <option value="Holding company">Holding company</option>
                                        <option value="Others">Others</option>
                                    </select>

                                    &nbsp;&nbsp;

                                    <label htmlFor="revenue">Revenue:&nbsp;</label>
                                    <select className='revenue_dropdown' id="revenue" name="revenue" required>
                                        <option value="<S$10,000">&lt;S$10,000</option>
                                        <option value="<S$100,000">&lt;S$100,000</option>
                                        <option value="<S$500,000">&lt;S$500,000</option>
                                        <option value="<S$1,000,000">&gt;S$500,000</option>
                                    </select>
                                    */}



                                    {/*
                                    <span>Services interested in (Optional)</span><br />

                                    <input className="service_checkbox" type="checkbox" id="services1" name="services1" value="Full Year Package" />
                                    <label htmlFor="services1"> Full Year Package</label><br />
                                    <input className="service_checkbox" type="checkbox" id="services2" name="services2" value="Bookeeping" />
                                    <label htmlFor="services2"> Bookeeping</label><br />
                                    <input className="service_checkbox" type="checkbox" id="services3" name="services3" value="Tax services" />
                                    <label htmlFor="services3"> Tax services</label><br />
                                    <input className="service_checkbox" type="checkbox" id="services4" name="services4" value="CFO for Hire" />
                                    <label htmlFor="services4"> CFO for Hire</label><br />
                                    <input className="service_checkbox" type="checkbox" id="services5" name="services5" value="Valuation" />
                                    <label htmlFor="services5"> Valuation</label><br />
                                    */}


                                    
                                </div>
                            </Grid>
                            <Grid className='' item xs={12} md={4}>
                                <div>
                                    <input className='form-input' type='email' placeholder='Email address' name='user_email' />
                                    {/*<input className='form-input' type='text' placeholder='Industry' name='industry' required />-*/}
                                    <label htmlFor="preferreddate">Preferred dates (optional):</label>
                                    <input className='form-input' type="datetime-local" id="preferreddate" name="preferreddate" />
                                </div>
                            </Grid>
                            <Grid className='' item xs={0} md={2}></Grid>

                            <Grid className='' item xs={0} md={2}></Grid>
                            <Grid className='' item xs={12} md={8}><textarea name='message' type='text' placeholder='Message' required></textarea></Grid>
                            <Grid className='' item xs={0} md={2}></Grid>

                            <Grid className='' item xs={0} md={2}></Grid>
                            <Grid className='' item xs={12} md={8}><input className='form-input' type='submit' value='Submit' id='input-submit' /></Grid>
                            <Grid className='' item xs={0} md={2}></Grid>    
                            
                        </Grid>
                        
                    </Box>
                </div>
                
            </form>
        </div>
    );
}

export default ContactForm;