//import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { AboutUs } from "./components/AboutUs";
//import { Cards } from "./components/Cards.js";
import { Services } from "./components/Services.js";
import { ContactUs } from "./components/ContactUs.js";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/ContactUs',
      element: <ContactUs />
   },
   {
    path: '/aboutus',
    element: <AboutUs />
   },
  {
    path: '/services',
    element: <Services />
   }
];

export default AppRoutes;
