import React, { Component } from 'react';
import './Home.css';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import ContactForm from './ContactForm';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

var wa_number = "+6591012667";
var wa_accountName = "A & T";

export class Home extends Component {


  render() {
      return (

          <div>

              <div className='Main-bg-test'> </div>

              <div>
                  <div className='Main-component container text-light'>
                      <h1> {wa_accountName} Accounting</h1>
                  </div>
              </div>


              <div className="container">
                  <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                              <div className='box2'>
                                  <h1 className='title2'> <span>Services We Provide.</span> <br /> Powered with Xero.  </h1>
                              </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                              <CardGroup>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card1 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Accounting</Card.Title>
                                          {/*<Card.Text>
                                              This is a wider card with supporting text below as a natural lead-in
                                              to additional content. This content is a little bit longer.
                                          </Card.Text> */}
                                      </Card.Body>
                                  </Card>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card2 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Financial Advisory</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card3 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Consolidation</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card4 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Tax-related Services</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                              </CardGroup>
                          </Grid>

                          <Grid item xs={12} md={12}>
                              <CardGroup>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card5 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Financial Transformation</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card6 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Streamlining of Processes</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                                  <Card className='Cards'>
                                      <Card.Img variant="top" className='card7 hover-zoom' />
                                      <Card.Body>
                                          <Card.Title>Preparation of Financial Statements</Card.Title>
                                          <Card.Text>
                                              
                                          </Card.Text>
                                      </Card.Body>
                                  </Card>
                              </CardGroup>
                            
                          </Grid>

                      </Grid>
                  </Box>
                  
              </div>

              {/*
              <div className="container">
                  <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                          <Grid item xs={0} md={2}> </Grid>
                          <Grid item xs={12} md={8}>
                              <h1 className="testimonial"> Testimonials </h1>
                          </Grid>
                          <Grid item xs={0} md={2}> </Grid>
                          <Grid item xs={12} md={12}>
                              <Item>xs=6 md=8</Item>
                          </Grid>
                      </Grid>
                  </Box>
              </div> 
              */}

              <div className="container">
                  <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard1'>
                              <h1 className="title"> Want to take more control of your business? </h1>
                          </Grid>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard2'>
                              <h1 className="title"> For Entrepenuers and Business Owners </h1>
                          </Grid>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard1'>
                              <h4 className="text"> We offer tailored accounting services, including bookkeeping, tax preparation, and financial analysis for businesses of all sizes. </h4>
                          </Grid>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard2'>
                              <h4 className="text"> We offer customized solutions and advanced technology to ensure accuracy and efficiency, saving you time and money. </h4>
                          </Grid>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard1'>
                              <img className="convincepics1"  /> 
                          </Grid>
                          <Grid item xs={0} md={0.5}>  </Grid>
                          <Grid item xs={12} md={5.5} className='bigcard2'>
                              <img className="convincepics2" /> 
                          </Grid>
                      </Grid>
                  </Box>
              </div> 


              <ContactForm />




              {/*

               whatsapp for call to action
               
               */}

              <FloatingWhatsApp phoneNumber={wa_number} accountName={wa_accountName} />
              

           </div>
    );

      
    }


}
