import React, { Component } from 'react';
import './Services.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

export class Services extends Component {
    static displayName = Services.name;


    render() {
        return (
            <div className="full-width">


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                        <Grid className='sbox1' item xs={12} md={8}>
                            <div className='sbox'>
                                <div className='stext-box'>
                                    <h1 className='stitle'>Our services</h1>
                                    <p className='scenter_para'>  From a self-employed entrepeneur to a multinational company, A&T Accounting provides a tailored accounting solution to suit your needs and requirements.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className='spicbox1' item xs={12} md={4}>  </Grid>



                        <Grid className='spicbox2' item xs={12} md={6}> </Grid>
                        <Grid className='sbox3' item xs={12} md={6}>
                            <div className='sbox3-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Accounting Services</h1>
                                    <p className='scenter_para2'>  Our accounting services include day-to-day bookkeeping, accounts payable and receivable, and bank reconciliations. We help you keep track of your financial transactions, providing accurate and timely financial information that you can use to make informed business decisions.
                                    </p>
                                </div>
                            </div>
                        </Grid>

                        <Grid className='sbox2' item xs={12} md={7}>
                            <div className='sbox2-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Financial Transformation</h1>
                                    <p className='scenter_para2'>  Our financial transformation services help you streamline your financial processes, automate manual tasks, and leverage technology to gain greater visibility into your financial performance. We help you optimize your financial operations to drive growth, reduce costs, and improve efficiency.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className='spicbox3' item xs={12} md={5}>  </Grid>

                        <Grid className='spicbox4' item xs={12} md={6}> </Grid>
                        <Grid className='sbox3' item xs={12} md={6}>
                            <div className='sbox3-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Financial Advisory</h1>
                                    <p className='scenter_para2'>  Our financial advisory services provide strategic guidance on financial planning, risk management, and investment strategies. We work closely with you to understand your business objectives and provide personalized advice that helps you achieve your financial goals.
                                    </p>
                                </div>
                            </div>
                        </Grid>

                        <Grid className='sbox2' item xs={12} md={7}>
                            <div className='sbox2-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Consolidation</h1>
                                    <p className='scenter_para2'>  Our consolidation services help you consolidate financial information from multiple sources, providing a single view of your financial performance. We help you streamline the consolidation process, reducing errors and improving accuracy.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className='spicbox5' item xs={12} md={5}>  </Grid>

                        <Grid className='spicbox6' item xs={12} md={6}> </Grid>
                        <Grid className='sbox3' item xs={12} md={6}>
                            <div className='sbox3-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Streamlining of Processes</h1>
                                    <p className='scenter_para2'>  Our process streamlining services help you identify areas for process improvement and optimize your financial operations. We help you eliminate inefficiencies, reduce costs, and improve accuracy and timeliness of financial information.
                                    </p>
                                </div>
                            </div>
                        </Grid>

                        <Grid className='sbox2' item xs={12} md={7}>
                            <div className='sbox2-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Tax-related Services</h1>
                                    <p className='scenter_para2'>  Our tax-related services include tax preparation, planning, and compliance. We help you navigate complex tax laws and regulations, minimizing your tax liability and ensuring compliance with all relevant tax laws.
                                    </p>
                                </div>
                            </div>
                        </Grid>
                        <Grid className='spicbox7' item xs={12} md={5}>  </Grid>

                        <Grid className='spicbox8' item xs={12} md={6}> </Grid>
                        <Grid className='sbox3' item xs={12} md={6}>
                            <div className='sbox3-sub'>
                                <div className='stext-box'>
                                    <h1 className='stitle2'>Preparation of Financial Statements</h1>
                                    <p className='scenter_para2'>  Our preparation of financial statements services include the preparation of financial statements such as income statements, balance sheets, and cash flow statements. We provide accurate and timely financial information that helps you make informed business decisions and meet regulatory reporting requirements.
                                    </p>
                                </div>
                            </div>
                        </Grid>



                    </Grid>

                </Box>



            </div>
        );
    }
}
